import { getDataObjectById } from 'o365.vue.ts';

self.addEventListener('message', offlineSyncDataObject)

function offlineSyncDataObject(event: any) {
    console.log(event);

    console.log(getDataObjectById(event.data.dataObjectId))
    debugger;

    // ---- Retrieve Record Count ---- //

    // ---- Retrieve Record Count Error Check ---- //

    let i = 0;

    do {
        // ---- Retrieve Record Page ---- //

        // ---- Retrieve Record Page Error Check ---- //
        
        if (this.isFileTable) {
            // ---- Retrieve Record Page Files ---- //

            // ---- Retrieve Record Page Files Error Check ---- //

            // ---- Bulk Create File Records ---- //

            // ---- Bulk Create File Records Error Check ---- //
        } else {
            // ---- Bulk Create Records ---- //

            // ---- Bulk Create Records Error Check ---- //
        }

        i++;
    } while (i < 10);

    // ---- Status Update ---- //

    // ---- Memory Check ---- //
}
